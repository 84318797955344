import { graphql, useStaticQuery } from 'gatsby'
import { Contentful_Image_QueryQuery } from '../generated/graphql'

export const useContentfulImage = (contentful_id: string) => {
  const { assets } = useStaticQuery<Contentful_Image_QueryQuery>(
    graphql`
      query CONTENTFUL_IMAGE_QUERY {
        assets: allContentfulAsset {
          edges {
            node {
              contentful_id
              file {
                url
              }
            }
          }
        }
      }
    `
  )
  const asset = assets.edges.find(
    ({ node }) => node.contentful_id === contentful_id
  )
  return asset
}
