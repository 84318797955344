import React from 'react'

export const SocialIcon = ({
  children,
  ...props
}: {
  children: React.ReactNode
} & React.HTMLProps<HTMLAnchorElement>) => {
  return (
    <a
      className="bg-accent rounded-md text-bg w-8 h-8 flex justify-center items-center text-xl"
      {...props}
    >
      {children}
    </a>
  )
}
