import React from 'react'
import { ErrorComponent } from '../components/Error'
import Seo from '../components/seo'
import Navigation from '../components/NavBar'
import { ContentfulRichText } from '../components/ContentfulRichText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { SocialIcon } from '../components/SocialIcon'
import { HomePageQueryQuery } from '../generated/graphql'
import { library, IconProp } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { WindowLocation } from '@reach/router'

library.add(fas, fab)

export const HomePageContainer = ({
  data,
  location,
  locale = 'en-US',
}: {
  data: HomePageQueryQuery
  location: WindowLocation
  locale?: string
}) => {
  if (!data.content) {
    return <ErrorComponent message="Unable to load Page" />
  }
  return (
    <div className="min-h-screen relative bg-bg dark">
      <Seo title={`Puzzle Ventures`} />
      <main className="h-full relative flex flex-col items-center">
        <div className="flex flex-col h-full w-full max-w-6xl">
          <Navigation location={location} locale={locale} />
          <div className="flex flex-col flex-auto pb-8 w-full">
            <div className="flex-auto flex flex-col items-center md:flex-row-reverse h-full relative w-full">
              <div className="fixed left-0 top-1/3 flex-auto flex-col items-center w-1/2 pointer-events-none z-10 hidden md:flex-row md:flex">
                <div className="flex-1 flex items-end">
                  <img
                    alt="puzzle-ventures-gif"
                    src={data.content.mainImage?.file?.url!}
                    className="object-contain w-full py-8 px-8 md:py-0 max-h-96"
                  />
                </div>
              </div>
              <div className="flex-auto flex flex-col w-full items-center md:flex-row h-full container max-w-xl">
                <div className="text-white max-w-full items-center flex-col relative h-full px-6 md:pr-8 md:items-start">
                  <div className="md:hidden">
                    <img
                      alt="puzzle-ventures-gif"
                      src={data.content.mainImage?.file?.url!}
                      className="object-cover w-full py-8 px-8 md:py-0"
                    />
                  </div>
                  <div className="pt-10 md:min-h-full flex flex-col items-start align-middle justify-center pb-20">
                    <div className={`hidden md:block w-full max-w-xl h-auto`}>
                      <img
                        src={data.content.headerTextBigScreen?.file?.url!}
                        className="w-full object-contain"
                        style={{
                          marginLeft: `${
                            data.content.headerTextBigOffset || -5
                          }%`,
                        }}
                      />
                    </div>
                    <div className="visible md:hidden w-full h-auto">
                      <img
                        src={data.content.headerTextSmallScreen?.file?.url!}
                        className="w-full object-contain"
                      />
                    </div>

                    <p className="mt-8 text-center md:text-left">
                      {data.content.tagline}
                    </p>
                  </div>
                  {data.content.subscribeText && (
                    <div
                      className="min-h-full flex flex-col items-start align-middle justify-center pb-32"
                      id="subscribe"
                    >
                      <h1>Subscribe</h1>
                      <ContentfulRichText richText={data.content.subscribeText} />
                    </div>
                  )}
                  {/* <div className="min-h-full flex flex-col items-start align-middle justify-center pb-32">
                    <h1>{data.content.writingTitle}</h1>
                    <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
                      {data.blogs.nodes.map((el) => (
                        <BlogSnippet data={el} key={el.id} />
                      ))}
                    </div>
                  </div> */}

                  <div
                    className="flex flex-col items-start align-middle justify-center"
                    id="contact"
                  >
                    <h2>
                      <a
                        href={`mailto:${data.content.email}`}
                        className="text-white underline decoration-accent"
                      >
                        {data.content.email}
                      </a>
                    </h2>
                    <ContentfulRichText richText={data.content.contactText} />
                    <div className="grid gap-4 grid-cols-3 mt-4">
                      {data.content.socialLink?.map((el) => (
                        <SocialIcon
                          key={el?.icon}
                          href={el?.link!}
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={el?.icon! as IconProp} />
                        </SocialIcon>
                      ))}
                    </div>
                    <p className="mt-4">
                      © 2020 - 2024, Puzzle Ventures. All Rights Reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
